const Events = () => import('@/views/events/Events.vue');
const Details = () => import('@/views/events/details/Details.vue');

export default [
  {
    path: 'events',
    meta: {
      label: 'Events'
    },
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: ':type',
        meta: {
          label: 'Übersicht'
        },
        component: Events,
      },
      {
        path: ':type/:id',
        meta: {
          label: 'Details'
        },
        component: Details,
      },
    ]
  },
]
