import axios from 'axios'
import auth from "@/auth/authService";
import store from '@/Store'
import Rollbar from 'rollbar';

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

export default {
  request: function(method, url, data, indicator = true){
    if(indicator){
      store.dispatch('addRequest', url);
    }
    if(!auth.isAuthenticated() && process.env.NODE_ENV === 'production'){
      auth.login({ target: window.location.pathname });
      return Promise.resolve({data: 'unauthenticated'});
    }

    return auth.getIdToken().then((token) => {
      return axios({
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        method: method,
        url: url,
        data: data
      })
      .then(function (response) {
        if(indicator){
          store.dispatch('deleteRequest');
        }
        return response.data;
      })
      .catch(function (error) {
        if(indicator){
          store.dispatch('deleteRequest');
        }
        if (typeof(error.response) === 'undefined' || typeof(error.response.data.errors) === 'undefined'){
          if(process.env.NODE_ENV === 'production') {
            let rollbar = new Rollbar({
              accessToken: process.env.VUE_APP_ROLLBAR_ACCESSTOKEN,
            });
            let user = store.getters.getUser;
            if(user !== null){
              rollbar.configure({
                payload: {
                  person: {
                    id: user.id,
                    username: user.name,
                    email: user.email
                  }
                }
              });
            }

            rollbar.error(error);
          }

          return Promise.reject(error);
        }
        store.dispatch('setErrorData', error.response.data.errors);
        store.dispatch('throwErrorMessage', 'Es ist ein Fehler aufgetreten:');
        return Promise.reject(error.response.data);
      });
    });
  },
  requestFile(url, filename, download = false, method = 'GET', mimeType = 'application/pdf'){
    let self = this;
    store.dispatch('addRequest', url);
    auth.getIdToken().then((token) => {
      return axios({
        responseType: 'arraybuffer',
        headers: {
          'Accept': mimeType,
          'Authorization': 'Bearer ' + token
        },
        method: method,
        url: url
      }).then(response => {
        store.dispatch('deleteRequest');

        const blob = new Blob([response.data], {
          type: mimeType,
        });

        if(download){
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = self.cleanFilename(filename) || 'download';
          link.click();
        }
        else {
          return blob;
        }

      }).catch(function (error) {
        store.dispatch('deleteRequest');
      });
    });
  },
  cleanFilename(filename){
    filename = filename.replaceAll('Ü', 'UE')
    filename = filename.replaceAll('ü', 'ue')
    filename = filename.replaceAll('Ö', 'OE')
    filename = filename.replaceAll('ö', 'oe')
    filename = filename.replaceAll('Ä', 'AE')
    filename = filename.replaceAll('ä', 'ae')

    let searchMask = "[^a-zA-Z0-9.]+";
    let regEx = new RegExp(searchMask, 'g');
    let replaceMask = "_";

    filename = filename.replace(regEx, replaceMask);
    return filename;
  }
}
